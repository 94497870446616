<template>
  <div class="box">
    <div :class="showtop ? 'top-box top-boxs' : 'top-box '">
      <img
        @click="showMEnu"
        class="topIcon"
        src="/subject/searchReport/wap/topIcon.png"
        alt=""
      />
      <img class="logo" src="/subject/searchReport/wap/logo.png" alt="" />
    </div>
    <div class="top-menu" v-show="showMenus">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="#070507"
        text-color="#fff"
        style="border: 0; width: 110px"
        active-text-color="#f00000"
      >
        <el-menu-item index="1">发布会直播</el-menu-item>
        <el-menu-item index="2">团队介绍</el-menu-item>
        <el-menu-item index="3">会议议程</el-menu-item>
        <el-menu-item index="4">投研报告</el-menu-item>
        <el-menu-item index="5">媒体报道</el-menu-item>
        <el-menu-item index="6">往届回顾</el-menu-item>
        <el-menu-item index="7">支持媒体</el-menu-item>
        <el-menu-item index="8">关注我们</el-menu-item>
      </el-menu>
    </div>
    <div class="bannerBox">
      <img class="banner" src="/subject/searchReport/wap/topbg.png" alt="" />

      <img
        class="top-title1"
        src="/subject/searchReport/wap/top-title1.png"
        alt=""
      />
      <img
        class="top-title"
        src="/subject/searchReport/wap/top-title.png"
        alt=""
      />
    </div>
    <div class="content">
      <div class="part part1" ref="targetElement">
        <img class="title" src="/subject/searchReport/wap/title.png" alt="" />
        <div class="contents">
          红狮集团专家团队，联袂全球金融资深分析师，携手打造<span>2025年极具投资参考价值的投研报告</span>行业独创发布
        </div>
        <!-- <div class="newVideoBox">
          <video class="craig" v-if="!ckplayerObject.live" :src="isVideo" controls="controls" autoplay="autoplay" muted></video>
          <video v-if="ckplayerObject.live" id="videoPlayer" class="video-js" muted></video>
          <div class="swiperVideo" v-if="!ckplayerObject.live">
            <swiper ref="videoSwiper" :options="swiperVideoOption"> 
              <div class="swiper-slide" v-for="(item,i) in teacherSwiper" :key="i">
                <img @click="goVideo(i)" :src="item" />
              </div>
             
            </swiper>
          </div>
        </div> -->
        <!-- <img class="team" src="/subject/searchReport/wap/team.png" alt="" /> -->
        <video
          class="team"
          src="/subject/searchReport/report.mp4"
          controls="controls"
          autoplay="autoplay"
         
        ></video>

        <div class="bottom-list">
          <img
            @click="live800"
            src="/subject/searchReport/wap/request.png"
            alt=""
          />
          <img
            @click="Login"
            src="/subject/searchReport/wap/login.png"
            alt=""
          />
        </div>
        <div class="bottom-text">
          咨询客服获取投研报告及<span>打开密码</span>
        </div>
      </div>
      <div class="part part2" ref="targetElement2">
        <img class="title" src="/subject/searchReport/wap/title2.png" alt="" />
        <div class="swiperBox">
          <swiper ref="mySwiper" :options="step_5_swiperOption">
            <div class="swiper-slide">
              <img class="one" src="/subject/searchReport/wap/teacher.png" />
              <div class="two">
                <div class="flex">
                  <img
                    class="teacher"
                    src="/subject/searchReport/wap/teacher.png"
                    alt=""
                  />
                  <div class="contents">
                    <div class="flex">
                      <img
                        class="pen"
                        src="/subject/searchReport/wap/pen.png"
                        alt=""
                      />
                      <div class="titles">交易箴言：</div>
                    </div>
                    <div class="titles1">顺势交易不与市场对做</div>
                    <div class="introduce">个人简介：</div>
                    <div class="cont">
                      台湾知名学府毕业，具备多年金融投资经验，自16年开始成为专职交易员，专注于贵金属、道琼斯指数和数字货币交易，热衷于区块链技术应用及数字货币领域分析，且擅长技术分析应用，K线形态、技术指标、斐波那契等，运用独特的技术分析方式去推算市场惯性及时间循环，并块链应用和去中心化金融发展，在数字货币领域靠着精湛的分析技术创造超过200%的做人成绩。
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <img class="one" src="/subject/searchReport/wap/teacher3.png" />
              <div class="two">
                <div class="flex">
                  <img
                    class="teacher"
                    src="/subject/searchReport/wap/teacher1.png"
                    alt=""
                  />
                  <div class="contents">
                    <div class="flex">
                      <img
                        class="pen"
                        src="/subject/searchReport/wap/pen.png"
                        alt=""
                      />
                      <div class="titles">交易箴言：</div>
                    </div>
                    <div class="titles1">
                      决不要因为失去耐心而退出市场，也决不要因为迫不及待而进入市场
                    </div>
                    <div class="introduce">个人简介：</div>
                    <div class="cont">
                      计算机科学硕士学位，曾在国外知名投资银行担任对冲经理人，特别专长于股票、期货对冲以及选择权价差交易。擅长以演算法找出选择权定价高估或低估的产品进行无风险套利。推算出风险平衡最佳化的股票与选择权投资组合，短线赚取价差获利，长线赚取大行情。致力于打造稳定赚取被动收入的价差组合，与出现行情时赚取高报酬低风险的投资组合。专长操作的金融产品为美股、期货、外汇、贵金属、指数选择权。
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <img class="one" src="/subject/searchReport/wap/teacher1.png" />
              <div class="two">
                <div class="flex">
                  <img
                    class="teacher"
                    src="/subject/searchReport/wap/teacher2.png"
                    alt=""
                  />
                  <div class="contents">
                    <div class="flex">
                      <img
                        class="pen"
                        src="/subject/searchReport/wap/pen.png"
                        alt=""
                      />
                      <div class="titles">交易箴言：</div>
                    </div>
                    <div class="titles1">别人贪婪我恐惧，别人恐惧我贪婪</div>
                    <div class="introduce">个人简介：</div>
                    <div class="cont">
                      澳洲墨尔本大学财经硕士，投资经验十年，取得CFA美国财务分析师...等多项国内外金融证照；曾在私人投资基金任职宏观经济研究员，股指外汇原物料皆有广泛涉猎。擅长从政策与数据变化中寻找投资机会，辅以成交量、均线与RSI...等指标综合创造进出场点位，有一套独特的趋势解读看法。
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <img class="one" src="/subject/searchReport/wap/teacher2.png" />
              <div class="two">
                <div class="flex">
                  <img
                    class="teacher"
                    src="/subject/searchReport/wap/teacher3.png"
                    alt=""
                  />
                  <div class="contents">
                    <div class="flex">
                      <img
                        class="pen"
                        src="/subject/searchReport/wap/pen.png"
                        alt=""
                      />
                      <div class="titles">交易箴言：</div>
                    </div>
                    <div class="titles1">
                      时间就是金钱，进场时机永远比你的目标价位重要
                    </div>
                    <div class="introduce">个人简介：</div>
                    <div class="cont">
                      美国名校毕业。专注于黄金、外汇和股指研究，具备丰富实战交易指导经验，精通宏观经济与货币政策分析，解读央行言论与报告，洞察市场基本面趋势。技术分析层面擅长主力區間，市场主力进出场时机判断有丰富经验。价格技术分析的优势在于筹码面交易的研究；对15分钟、1小时、4小时和日线之间的切换研究有深刻体会。尤其在日内交易、主力动向判断及风险控制方面具有丰富经验。
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </swiper>
        </div>

        <div class="bott">
          分析师的观点仅代表分析师个人立场，不代表本公司立场投资有风险，入市需谨慎！
        </div>

        <p>
          <img
            @click="live800"
            src="/subject/searchReport/wap/request.png"
            alt=""
          />
          <img
            @click="Login"
            src="/subject/searchReport/wap/login.png"
            alt=""
          />
        </p>
        <div class="bottom-text">
          咨询客服获取投研报告及<span>打开密码</span>
        </div>
      </div>
      <div class="part part3" ref="targetElement3">
        <img class="title" src="/subject/searchReport/wap/title3.png" alt="" />
        <div class="contents">
          多年投资经验不断总结，不断实践数月精心准备打造<span>2025年极具参考价值的投资方案内容</span>汇集投资干货，极具实操价值
        </div>
        <img
          class="part3-cont"
          src="/subject/searchReport/wap/background.png"
          alt=""
        />
        <div class="part3-title">2025全球投资机会研究报告-发布会议程</div>
        <div class="timelist">
          <div class="left-time">
            <div>
              <span>14:00</span>
              <div class="left-flex">
                <img src="/subject/searchReport/icon.png" alt="" />
                <div class="time-line" style="height: 17px"></div>
              </div>
              <div class="times">
                <span>开场致词</span>
              </div>
            </div>
            <div>
              <span>14:05</span>
              <div class="left-flex">
                <img src="/subject/searchReport/icon.png" alt="" />
                <div class="time-line" style="height: 60px"></div>
              </div>
              <div class="times">
                <span>主题演讲</span>

                <div class="times1">
                  <img src="/subject/searchReport/avatar.png" alt="" />
                  <div>
                    <span>主题：美联储降息循环开启对全球资产价格的影响</span>
                    <span>专家：吴龙（红狮金融研究院资深分析师）</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <span>14:35</span>
              <div class="left-flex">
                <img src="/subject/searchReport/icon.png" alt="" />
                <div class="time-line" style="height: 82px"></div>
              </div>
              <div style="display: flex;flex-direction: column;">
                <div class="times">
                <span>主题演讲</span>
                  <div class="times1">
                  <img src="/subject/searchReport/avatar1.png" alt="" />
                  <div>
                    <span>主题：中国经济稳中求进展，政策组合带动经济复苏</span>
                    <span>欧洲央行降息对欧元与经济的影响</span>

                    <span>专家：万良（红狮金融研究院资深分析师）</span>
                  </div>
                </div>
                
              </div>
              
              </div>
            </div>
            <div>
              <span>15:05</span>
              <div class="left-flex">
                <img src="/subject/searchReport/icon.png" alt="" />
                <div class="time-line" style="height: 53px"></div>
              </div>
              <div class="times">
                <span>主题演讲</span>
                 <div class="times1">
                  <img src="/subject/searchReport/avatar2.png" alt="" />
                  <div>
                    <span>主题：2025年全球各大资产投资热点分析</span>
                <span>专家：马文（红狮金融研究院资深分析师）</span>
                  </div>
                </div>
                
              </div>
            </div>
            <div>
              <span>15:35</span>
              <div class="left-flex">
                <img src="/subject/searchReport/icon.png" alt="" />
                <div class="time-line" style="height: 82px"></div>
              </div>
              <div style="display: flex;flex-direction: column;">
                  <div class="times">
                <span>主题演讲</span>
                   <div class="times1">
                  <img src="/subject/searchReport/avatar3.png" alt="" />
                  <div>
                   <span>主题：地缘局势震荡对全球投资的影响</span>
                   <span>    美国总统大选对全球投资品种的后续影响</span>

                <span>专家：明杰（红狮金融研究院资深分析师）</span>
                  </div>
                </div>
                
              </div>
               
              </div>
             
            </div>
            <div>
              <span>16:05</span>
              <div class="left-flex">
                <img src="/subject/searchReport/icon.png" alt="" />
                <div class="time-line" style="height: 53px"></div>
              </div>
              <div class="times">
                <span>圆桌会议</span>
                     <div class="times1">
                  <img src="/subject/searchReport/avatar3.png" alt="" />
                  <div>
                    <span>主题：地缘局势震荡对全球投资的影响</span>
                <span>专家：吴龙  万良  马文  明杰</span>
                  </div>
                </div>
              
              </div>
            </div>
            <div>
              <span>16:35</span>
              <img src="/subject/searchReport/icon.png" alt="" />
              <div class="times">
                <span>答疑互动环节</span>
              </div>
            </div>
          </div>
        </div>
        <p>
          <img
            @click="live800"
            src="/subject/searchReport/wap/request.png"
            alt=""
          />
          <img
            @click="Login"
            src="/subject/searchReport/wap/login.png"
            alt=""
          />
        </p>
        <div class="bottom-text">
          咨询客服获取投研报告及<span>打开密码</span>
        </div>
      </div>
      <div class="part part4" ref="targetElement4">
        <img class="title" src="/subject/searchReport/wap/title4.png" alt="" />
        <!-- <img class="other" src="/subject/newReport/wap/content4.png" alt=""> -->
        <!-- <img @click="downloadReport" class="download" src="/subject/newReport/wap/download.png" alt=""> -->
        <div class="contents">
          2024/12/18 <span>重磅推出</span>《2025年投资机会研究报告》
        </div>
        <div class="swiperBox">
          <swiper ref="mySwiper" :options="swiperOption">
            <div class="swiper-slide">
              <img src="/subject/searchReport/wap/banner2.png" />
            </div>
            <div class="swiper-slide">
              <img src="/subject/searchReport/wap/report1.png" />
            </div>
            <div class="swiper-slide">
              <img src="/subject/searchReport/wap/report2.png" />
            </div>
            <!-- 分页器 -->
            <!-- <div class="swiper-pagination" slot="pagination"></div> -->
          </swiper>
        </div>
        <div class="contents">
          股市、期货、黄金、外汇、数字货币、房地产具体投资策略一一展开，内容翔实，助您实战一臂之力
        </div>
        <div class="contents" style="margin: 0">
          <span>名额有限</span> 送完即止！
        </div>
        <div class="bottom-text">
          咨询客服获取投研报告及<span>打开密码</span>
        </div>
        <p>
          <img
            @click="live800"
            src="/subject/searchReport/wap/request.png"
            alt=""
          />
          <img
            @click="Login"
            src="/subject/searchReport/wap/login.png"
            alt=""
          />
        </p>
      </div>
      <div class="part part5" ref="targetElement5">
        <img class="title" src="/subject/searchReport/wap/title5.png" alt="" />
        <div class="titles">揭秘金融领域的热点动态与趋势分析</div>
        <!-- <img class="other" src="/subject/searchReport/wap/report.png" alt="" /> -->
        <div class="swiperbox">
          <swiper ref="mySwiper" :options="swiperTeacherOption">
            <div class="swiper-slide" style="width: 100%">
              <img
                class="other"
                src="/subject/searchReport/wap/report.png"
                alt=""
              />
              <div class="bottoms">
                美联社报道《2024年投资机会研究报告》
              </div>
            </div>
            <div class="swiper-slide" style="width: 100%">
              <img
                class="other"
                src="/subject/searchReport/wap/reports2.png"
                alt=""
              />
              <div class="bottoms">
                目标国家/行业相关媒体报道《2024年投资机会研究报告》
              </div>
            </div>
            <div class="swiper-slide" style="width: 100%">
              <img
                class="other"
                src="/subject/searchReport/wap/reports.png"
                alt=""
              />
              <div class="bottoms">
                南方经济媒体报道《2024年投资机会研究报告》
              </div>
            </div>
            <div class="swiper-slide" style="width: 100%">
              <img
              
                src="/subject/searchReport/wap/reports1.png"
                alt=""
              />
              <div class="bottoms">
                新浪财经媒体报道《2024年投资机会研究报告》
              </div>
            </div>
            <div class="swiper-slide" style="width: 100%">
              <img
             
                src="/subject/searchReport/product.png"
                alt=""
              />
              <div class="bottoms">
                中国产业报道《2024年投资机会研究报告》
              </div>
            </div>
            <div class="swiper-slide">
              <img  src="/subject/searchReport/compony.png" />
              <div class="bottoms">
                南方企业报道《2024年投资机会研究报告》
              </div>
            </div>
            <div class="swiper-slide">
              <img  src="/subject/searchReport/compony.png" />
              <div class="bottoms">
                南方企业报道《2024年投资机会研究报告》
              </div>
            </div>
            <div class="swiper-slide">
              <img  src="/subject/searchReport/guge.png" />
              <div class="bottoms">
                谷歌报道《2024年投资机会研究报告》
              </div>
            </div>
            <div class="swiper-slide">
              <img  src="/subject/searchReport/yahu.png" />
              <div class="bottoms">
                雅虎报道《2024年投资机会研究报告》
              </div>
            </div>
             <div class="swiper-slide">
              <img src="/subject/searchReport/gelun.png" />
              <div class="bottoms">
                美国哥伦报道《2024年投资机会研究报告》
              </div>
            </div>
            <div class="swiper-slide">
              <img  src="/subject/searchReport/fox.png" />
              <div class="bottoms">
                福克斯报道《2024年投资机会研究报告》
              </div>
            </div>
            <div class="swiper-slide">
              <img  src="/subject/searchReport/fox1.png" />
              <div class="bottoms">
                福克斯报道《2024年投资机会研究报告》
              </div>
            </div>
            <div class="swiper-slide">
              <img src="/subject/searchReport/Americ.png" />
              <div class="bottoms">
                美国广播公司报道《2024年投资机会研究报告》
              </div>
            </div>
             <div class="swiper-slide">
              <img  src="/subject/searchReport/Americ1.png" />
              <div class="bottoms">
                美国广播公司报道《2024年投资机会研究报告》
              </div>
            </div>
             <div class="swiper-slide">
              <img  src="/subject/searchReport/Americ2.png" />
              <div class="bottoms">
                美国广播公司报道《2024年投资机会研究报告》
              </div>
            </div>
             <div class="swiper-slide">
              <img  src="/subject/searchReport/Americ3.png" />
              <div class="bottoms">
                美国广播公司报道《2024年投资机会研究报告》
              </div>
            </div>
            <!-- 分页器 -->
            <!-- <div class="swiper-pagination" slot="pagination"></div> -->
          </swiper>
        </div>
        <p>
          <img
            @click="live800"
            src="/subject/searchReport/wap/request.png"
            alt=""
          />
          <img
            @click="Login"
            src="/subject/searchReport/wap/login.png"
            alt=""
          />
        </p>
        <div class="bottom-text">
          咨询客服获取投研报告及<span>打开密码</span>
        </div>
      </div>
      <div class="part part6" ref="targetElement6">
        <img class="title" src="/subject/searchReport/wap/title6.png" alt="" />
        <div class="titles">探寻金融发展的足迹与变革之路</div>
        <!-- <img class="other" src="/subject/searchReport/wap/banner.png" alt="" /> -->
        <div class="swiperbox">
          <swiper ref="mySwiper" :options="swiperTeacherOption">
            <div class="swiper-slide" style="width: 100%">
              <img
                @click="toUrl1"
                class="other"
                src="/subject/searchReport/wap/banner.png"
                alt=""
              />
            </div>
            <div class="swiper-slide" style="width: 100%">
              <img
                @click="toUrl"
                class="other"
                src="/subject/searchReport/wap/banner1.png"
                alt=""
              />
            </div>

            <!-- 分页器 -->
            <!-- <div class="swiper-pagination" slot="pagination"></div> -->
          </swiper>
        </div>
        <p>
          <img
            @click="live800"
            src="/subject/searchReport/wap/request.png"
            alt=""
          />
          <img
            @click="Login"
            src="/subject/searchReport/wap/login.png"
            alt=""
          />
        </p>
        <div class="bottom-text">
          咨询客服获取投研报告及<span>打开密码</span>
        </div>
      </div>
      <div class="part part7" ref="targetElement7">
        <img class="title" src="/subject/searchReport/wap/title7.png" alt="" />
        <div class="titles">合作推广平台展示</div>
        <img class="other" src="/subject/searchReport/wap/medie.png" alt="" />
        <p>
          <img
            @click="live800"
            src="/subject/searchReport/wap/request.png"
            alt=""
          />
          <img
            @click="Login"
            src="/subject/searchReport/wap/login.png"
            alt=""
          />
        </p>
        <div class="bottom-text">
          咨询客服获取投研报告及<span>打开密码</span>
        </div>
      </div>
      <div class="part part8" ref="targetElement8">
        <img class="title" src="/subject/searchReport/wap/title8.png" alt="" />
        <div class="titles">洞察金融市场的脉动，共筑财富增长之路</div>
        <div class="app-box">
          <div class="codeBody">
            <div>MT4账户专用版本</div>
            <div class="codeBox">
              <div class="codeItem">
                <Qrcode :link="iosMt4DownloadUrl" :size="126" :iconSize="30" />
                <div>iOS</div>
              </div>
              <div class="codeItem">
                <Qrcode :link="androidMt4DownloadUrl" :size="126" />
                <div>Android</div>
              </div>
            </div>
          </div>
          <div class="codeBody">
            <div>MT5账户专用版本</div>
            <div class="codeBox">
              <div class="codeItem">
                <Qrcode :link="iosMt5DownloadUrl" :size="126" />
                <div>iOS</div>
              </div>
              <div class="codeItem">
                <Qrcode :link="androidDownloadUrl" :size="126" />
                <div>Android</div>
              </div>
            </div>
          </div>
          <div class="codeBody">
            <div>下载官方APP，发现更多智富可能</div>
          </div>
        </div>
        <div class="serveBox">
          <div class="serveBox-item">
            <span>1、</span>MT4版本用MT4账户，MT5版本用MT5账户，两者不互通；
          </div>
          <div class="serveBox-item">
            <span>2、</span>
            <div>
              本平台2024年8月31日10：00之前开立的账户，均为MT4账户。之后开立的账户均为MT5账户。如果不确定，
              请联系<span class="serveBox-serve" @click="live800">在线客服</span
              >查询。
            </div>
          </div>
        </div>
        <img class="other" src="/subject/searchReport/wap/bg8.png" alt="" />
      </div>
    </div>
    <!-- <contactus v-if="!isApp"></contactus> -->
  </div>
</template>

<script>
import Contactus from "../../Contactus.vue";
import { livingRoomNow } from "../../../../api/info";
import videojs from "video.js";
import { mapState } from "vuex";
import Qrcode from "../../QrCode/index.vue";
export default {
  name: "newReportWap",
  components: {
    Contactus,
    Qrcode,
  },
  data() {
    return {
      menuList: [
        "发布会直播",
        "团队介绍",
        "会议议程",
        "投研报告",
        "媒体报道",
        "往届回顾",
        "支持媒体",
        "关注我们",
      ],
      showMenus: false,
      targetElements: null,
      // swiper 参数
      swiperOption: {
        slidesPerView: 3,
        // swiper图片之间的间隔
        spaceBetween: 140,
        centeredSlides: true,
        grabCursor: true,
        loop: true,
        preventClicks: true,
        //自动播放
        autoplay: {
          delay: 4000, //延迟播放下一张图片
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      swiperVideoOption: {
        slidesPerView: 3,
        // swiper图片之间的间隔
        spaceBetween: 50,
        centeredSlides: true,
        grabCursor: true,
        loop: true,
        preventClicks: true,
        //自动播放
        autoplay: {
          delay: 4000, //延迟播放下一张图片
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      swiperTeacherOption: {
        slidesPerView: 1,
        // swiper图片之间的间隔
        spaceBetween: 50,
        centeredSlides: true,
        grabCursor: true,
        loop: true,
        preventClicks: true,
        //自动播放
        autoplay: {
          delay: 4000, //延迟播放下一张图片
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      swiperTeacherOption1: {
        slidesPerView: 1,
        // swiper图片之间的间隔
        spaceBetween: 0,
        centeredSlides: true,
        grabCursor: true,
        loop: true,
        preventClicks: true,
        //自动播放
        autoplay: {
          delay: 4000, //延迟播放下一张图片
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      // 讲师轮播
      step_5_swiperOption: {
        slidesPerView: 2,
        // swiper图片之间的间隔
        spaceBetween: 20,
        centeredSlides: true,
        grabCursor: true,
        loop: true,
        preventClicks: true,
      },
      isVideo: "https://www.rlcvipltd.net/static/video/v-0320.mp4",
      screenWidth: null,
      scroll: Number,
      isApp: false,
      showtop: true,
      timer: null,
      myPlyer: null,
      options: null,
      activeIndex: null,
      currentIndex: 0, // 当前显示的图片的索引
      timer: null, // 自动切换的定时器
      teacherSwiper: [
        "/subject/newReport/wap/1.png",
        "/subject/newReport/wap/2.png",
        "/subject/newReport/wap/3.png",
        "/subject/newReport/wap/4.png",
        "/subject/newReport/wap/5.png",
      ],
    };
  },
  computed: {
    ckplayerObject() {
      return this.$store.state.home.ckplayerObject;
    },
    teacherPlayBack() {
      return this.$store.state.home.teacherPlayBack;
    },
    ...mapState("common", {
      androidMt4DownloadUrl: "androidMt4DownloadUrl",
      androidDownloadUrl: "androidDownloadUrl",
      iosMt4DownloadUrl: "iosMt4DownloadUrl",
      iosMt5DownloadUrl: "iosMt5DownloadUrl",
    }),
  },

  created() {
    // 传给App.vue,不展示tabbar
    this.$emit("controlShow", true);
    if (this.$route.query.isApp) {
      this.isApp = true;
    }

    // this.init();
    // this.timer = window.setInterval(() => {
    //   setTimeout(this.init(), 0);
    // }, 1000 * 10);
    // this.playBack();
  },
  beforeDestroy() {
    //在 beforeDestroy生命周期函数中销毁定时器
    clearInterval(this.timer);
    this.timer = null;
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
    // setTimeout(()=>{
    //   this.$nextTick(() => {
    //     this.initVideo(this.isVideo);
    //   })
    // },500)
  },
  watch: {
    screenWidth: function (n) {
      if (n > 500) {
        this.$router.push({
          name: "searchReportPC",
        });
      }
    },
  },
  methods: {
    toUrl() {
      window.open("https://www.rlcproltd.net/#/subject/tg_9654/index");
    },
    toUrl1() {
      window.open("https://www.rlcproltd.net/#/subject/newReport/index");
    },
    // 选择导航
    handleSelect(key) {
      console.log(key);
      switch (key) {
        case "1":
          this.activeIndex = key;

          this.targetElements = this.$refs.targetElement;
          this.scrollToTarget();
          this.showMenus = false;
          break;
        case "2":
          this.activeIndex = key;
          this.targetElements = this.$refs.targetElement2;

          this.scrollToTarget();
          this.showMenus = false;

          break;
        case "3":
          this.activeIndex = key;
          this.targetElements = this.$refs.targetElement3;

          this.scrollToTarget();
          this.showMenus = false;

          break;
        case "4":
          this.activeIndex = key;
          this.targetElements = this.$refs.targetElement4;

          this.scrollToTarget();
          this.showMenus = false;

          break;
        case "5":
          this.activeIndex = key;
          this.targetElements = this.$refs.targetElement5;

          this.scrollToTarget();
          this.showMenus = false;

          break;
        case "6":
          this.activeIndex = key;
          this.targetElements = this.$refs.targetElement6;

          this.scrollToTarget();
          this.showMenus = false;

          break;
        case "7":
          this.activeIndex = key;
          this.targetElements = this.$refs.targetElement7;

          this.scrollToTarget();
          this.showMenus = false;

          break;

        case "8":
          this.activeIndex = key;

          this.targetElements = this.$refs.targetElement8;

          this.scrollToTarget();
          this.showMenus = false;

          break;
      }
      console.log(this.targetElements, this.activeIndex);
    },
    // 滚动导航
    scrollToTarget() {
      const targetPosition = this.targetElements.offsetTop;
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    },
    // 显示导航菜单
    showMEnu() {
      this.showMenus = !this.showMenus;
    },
    goBack() {
      window.location.href = window.location.origin;
    },
    live800() {
      window.open(this.$parent.live800UrlUc);
    },
    Login() {
      window.open("https://www.rlcvipltd.net/uc/login");
    },
    // 获取是否直播以及直播流
    init() {
      livingRoomNow({ platid: 1 })
        .then((res) => {
          if (res.Status == 0 && res.content.length > 0) {
            this.$store.commit("home/set_ckplayerObject", {
              video: res.content[0].param.pcurl,
              live: true,
            });
            this.isVideo = res.content[0].param.pcurl;
            setTimeout(() => {
              this.initVideo(this.isVideo);
            }, 500);
          } else {
            this.$store.commit("home/set_ckplayerObject", {
              video: "https://www.rlcvipltd.net/static/video/v-0320.mp4",
              live: false,
            });
            this.isVideo = "https://www.rlcvipltd.net/static/video/v-0320.mp4";
            // this.myPlyer.dispose();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 初始化videojs内容
    initVideo(nowPlayVideoUrl) {
      // 这些options属性也可直接设置在video标签上，见 muted
      this.options = {
        autoplay: true, // 设置自动播放
        controls: true, // 显示播放的控件
        sources: [
          // 注意，如果是以option方式设置的src,是不能实现 换台的 (即使监听了nowPlayVideoUrl也没实现)
          {
            src: nowPlayVideoUrl,
            type: "application/x-mpegURL", // 告诉videojs,这是一个hls流
          },
        ],
      };
      // videojs的第一个参数表示的是，文档中video的id
      this.myPlyer = videojs(
        "videoPlayer",
        this.options,
        function onPlayerReady() {
          console.log("onPlayerReady 中的this指的是：", this); // 这里的this是指Player,是由Videojs创建出来的实例
          console.log(this.myPlyer === this); // 这里返回的是true
        }
      );
    },
    // 控制顶部导航栏的定位
    handleScroll() {
      let scrollY = window.scrollY; //获取滚动后的高度
      console.log(scrollY);
      if (scrollY > 500) {
        this.showtop = false;
      } else {
        this.showtop = true;
      }
    },
    // 下载报告书
    downloadReport() {
      window.open(
        "https://oss.0790jiaxiao.com/Report/SpingSummerInvestment2023032801.pdf"
      );
    },
    // 获取老师直播回放
    async playBack() {
      const data = {
        platid: 1,
        sort: "ut,DESC",
        type: 0,
        page: 0,
        size: 10,
      };
      await this.$store.dispatch("home/getPlayBack", data);
    },
    // 跳转视频回放
    goVideo(attr) {
      // console.log(this.teacherPlayBack);
      switch (attr) {
        case 0:
          window.open(this.teacherPlayBack[4].fpath);
          break;
        case 1:
          window.open(this.teacherPlayBack[3].fpath);
          break;
        case 2:
          window.open(this.teacherPlayBack[2].fpath);
          break;
        case 3:
          window.open(this.teacherPlayBack[1].fpath);
          break;
        case 4:
          window.open(this.teacherPlayBack[0].fpath);
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.top-box {
  width: 100%;
  height: 45px;
  background: #000002;
  position: fixed;
  padding-left: 12px;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  .topIcon {
    width: 16px;
    height: 12px;
    margin-right: 20%;
  }
  .logo {
    width: 125px;
  }
  z-index: 9999;
}
.top-boxs {
  background: rgba(0, 0, 2, 0);
}
.top-menu {
  position: fixed;
  top: 40px;
  left: 0;
  width: 110px;
  z-index: 10;
  div {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 12px;

    text-align: center;
    color: #ffffff;
    opacity: 0.8;
    background: rgba(0, 0, 2, 0.7);
  }
  .acticve {
    color: #f00000;
  }
}
.box {
  width: 100%;
  max-width: 500px;

  .bannerBox {
    width: 100%;
    position: relative;
    .banner {
      width: 100%;
      // display: block;
    }

    .top-title1 {
      position: absolute;
      top: 20%;
      left: 0%;
      width: 100%;
      height: 192px;
    }
    .top-title {
      position: absolute;
      top: 80%;
      left: 28%;
      width: 179px;
      height: 82px;
    }
  }
  .content {
    width: 100%;
    height: 3060px;
    background: url("/subject/searchReport/wap/bg.png") no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding-top: 16px;
    .part {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 16px;
      position: relative;
      .title {
        width: 100%;
      }
      .other {
        width: 344px;
      }

      p {
        width: 100%;
        // position: absolute;
        // bottom: 2%;
        // left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 24px;
        img {
          width: 127px;
          margin: 0 10px;
        }
      }
      .live800text {
        width: 120px;
        position: absolute;
        bottom: 1%;
        left: 15%;
      }
    }
    .part1 {
      margin: 50px 0;
      .title {
        width: 229.5px;
        height: 58px;
      }
      .contents {
        margin: 11px 1rem 23px;
        width: 300px;
        // height: 32.5px;
        font-size: 12px;

        font-weight: 700;
        text-align: center;
        color: #333333;
        line-height: 20px;
        span {
          color: #f00000;
        }
      }
      .team {
        // margin-left: 2rem;
        width: 90%;
        margin-bottom: 24px;
      }
      .bottom-list {
        // width: 100%;
        display: flex;
        align-items: center;
        padding: 0 1rem;
        img {
          width: 145px;
          height: auto;
        }
      }
      .bottom-list img:nth-child(1) {
        margin-right: 20px;
      }
      .bottom-text {
        margin: 12px 0 0px;
        font-size: 9px;

        font-weight: 700;
        text-align: center;
        color: #333333;
        span {
          color: #f00000;
        }
      }
      .newVideoBox {
        width: auto;
        height: 165px;
        position: relative;
        .craig {
          width: 344px;
          height: 165px;
        }
        .swiperVideo {
          width: 344px;
          height: 70px;
          position: absolute;
          bottom: -20%;
          left: 50%;
          transform: translate(-50%, -50%);
          .swiper-container {
            width: 100%;
            height: 100%;
            .swiper-wrapper {
              .swiper-slide {
                img {
                  width: 120px;
                  object-fit: cover;
                }
                img:hover {
                  border: 1px solid #fff;
                  border-radius: 5px;
                }
              }
            }
          }
        }
      }
    }
    .part2 {
      background: url("/subject/searchReport/wap/bg2.png") no-repeat;
      background-size: 100% 100%;
      margin-top: 0;
      padding: 50px 0px 78px 0px;
      .title {
        width: 229.5px;
        height: 82px;
        margin-bottom: 24px;
      }
      .flex {
        display: flex;
        align-items: center;
      }
      .contents {
        background: #fff;
        padding: 15px 12px 40px;
        width: 219px;
        height: 274px;
        box-sizing: border-box;
        .pen {
          width: 18px;
          height: 15px;
        }
        .titles {
          font-size: 11px;

          font-weight: 700;
          text-align: left;
          color: #f00000;
          line-height: 20px;
        }
        .titles1 {
          font-size: 10px;

          font-weight: 400;
          text-align: left;
          color: #f00000;
          line-height: 18px;
          margin: 8px 0 12px;
        }
        .introduce {
          font-size: 11px;

          font-weight: 700;
          text-align: left;
          color: #1a1a1a;
          line-height: 20px;
        }
        .cont {
          font-size: 10px;
          height: 154px;
          font-weight: 400;
          text-align: left;
          color: #999999;
          line-height: 18px;
          margin-top: 8px;
          box-sizing: border-box;
        }
      }
      .content-box {
        display: flex;
        align-items: center;
      }
      .bott {
        width: 225px;
        height: 25.5px;
        font-size: 9px;

        font-weight: 400;
        text-align: center;
        color: #cccccc;
        line-height: 16px;
        margin: 15px 0 24px 0;
      }
      .bottom-text {
        margin: 12px 0 24px;
        font-size: 9px;

        font-weight: 700;
        text-align: center;
        color: #fff;
        span {
          color: #f00000;
        }
      }
      .teacher {
        width: 120px;
        height: 274px;
      }
      p {
        bottom: 2%;
      }
      .live800text {
        bottom: 0.2%;
      }

      .swiperBox {
        width: 100%;
        height: 300px;

        .swiper-container {
          width: 100%;
          height: 300px;

          .swiper-wrapper {
            .swiper-slide {
              img {
                height: 140px;
                object-fit: cover;
              }

              .one {
                display: block;
              }

              .two {
                display: none;
              }
              .flex {
                width: 100%;
                display: flex;
                align-items: center;
              }
              .contents {
                background: #fff;
                padding: 15px 12px 40px;
                width: 219px;
                height: 300px;
                box-sizing: border-box;
                .pen {
                  width: 18px;
                  height: 15px;
                }
                .titles {
                  font-size: 11px;

                  font-weight: 700;
                  text-align: left;
                  color: #f00000;
                  line-height: 20px;
                }
                .titles1 {
                  font-size: 10px;

                  font-weight: 400;
                  text-align: left;
                  color: #f00000;
                  line-height: 18px;
                  margin: 8px 0 12px;
                }
                .introduce {
                  font-size: 11px;

                  font-weight: 700;
                  text-align: left;
                  color: #1a1a1a;
                  line-height: 20px;
                }
                .cont {
                  font-size: 10px;
                  height: 154px;
                  font-weight: 400;
                  text-align: left;
                  color: #999999;
                  line-height: 18px;
                  margin-top: 8px;
                  box-sizing: border-box;
                }
              }
              .content-box {
                display: flex;
                align-items: center;
              }
              .bott {
                width: 225px;
                height: 25.5px;
                font-size: 9px;

                font-weight: 400;
                text-align: center;
                color: #cccccc;
                line-height: 16px;
                margin: 15px 0 24px 0;
              }
              .bottom-text {
                margin: 12px 0 24px;
                font-size: 9px;

                font-weight: 700;
                text-align: center;
                color: #fff;
                span {
                  color: #f00000;
                }
              }
              /* Center slide text vertically */
              display: -webkit-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              -webkit-justify-content: center;
              justify-content: center;
              -webkit-box-align: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              align-items: center;
              transition: 300ms;
            }

            .swiper-slide-active,
            .swiper-slide-duplicate-active {
              img {
                height: 300px;
              }

              .two {
                display: block;
              }

              .one {
                display: none;
              }
            }
          }
        }
      }
    }
    .part3 {
      margin-top: 50px;
      .part3-cont {
        width: 98%;
        height: 683px;
        margin: 0 2rem;
      }
      .part3-title {
        position: absolute;
        top: 22%;
        left: 40px;
        font-size: 14px;

        font-weight: 700;
        text-align: center;
        color: #ffffff;
      }
      .title {
        width: 138.5px;
        height: 58.5px;
      }
      .bottom-text {
        margin: 12px 0 50px;
        font-size: 9px;

        font-weight: 700;
        text-align: center;
        color: #333333;
        span {
          color: #f00000;
        }
      }
      .contents {
        margin: 11px 1rem 23px;
        width: 300px;
        // height: 32.5px;
        font-size: 12px;

        font-weight: 700;
        text-align: center;
        color: #333333;
        line-height: 20px;
        span {
          color: #f00000;
        }
      }
      .timelist {
        position: absolute;
        top: 28%;
        left: 40px;
        font-size: 11px;

        font-weight: 700;
        text-align: left;
        color: #ffffff;
        display: flex;
        // align-items: center;
        .time-line {
          width: 0px;
          height: 234px;
          opacity: 0.4;
          border: 1px dashed #ffffff;
        }
        .left-flex {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            width: 12px;
            height: 12px;
          }
        }
        .left-time,
        .right-time {
          display: flex;
          flex-direction: column;
        }
        .left-time {
          margin-right: 50px;
        }
        div {
          display: flex;
          // align-items: center;
          img {
            margin: 0 7px;
            width: 4px;
            height: 4px;
          }
          .times {
            display: flex;
            flex-direction: column;
            // align-items: center;
            font-size: 11px;
          }
          .times1 {
            display: flex;
            align-items: center;
            font-size: 9px;
            font-weight: 400;
            text-align: left;
            color: #ffffff;
            line-height: 16px;
            img {
              width: 24px;
              height: 24px;
              margin: 0 7px 0 0;
            }
            div {
              display: flex;
              flex-direction: column;
            }
          }
          .times span:nth-child(1) {
            margin-bottom: 10px;
          }
        }
      }
      p {
        bottom: 5%;
      }
      .live800text {
        bottom: 1.2%;
      }
    }
    .part4 {
      padding: 50px 0 78px 0;
      background: url("/subject/searchReport/wap/bg4.png") no-repeat;
      background-size: 100% 100%;

      margin-top: 0;
      .title {
        width: 230px;
        height: 58.5px;
      }
      .bottom-text {
        margin: 12px 0 0px;
        font-size: 9px;

        font-weight: 700;
        text-align: center;
        color: #fff;
        span {
          color: #f00000;
        }
      }
      .contents {
        margin: 11px 1rem 23px;
        width: 300px;
        // height: 32.5px;
        font-size: 12px;

        font-weight: 700;
        text-align: center;
        color: #fff;
        line-height: 20px;
        span {
          color: #f00000;
        }
      }
      p {
        bottom: 3%;
      }
      .download {
        width: 175px;
        position: absolute;
        top: 28%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .swiperBox {
        width: 90%;
        // position: absolute;
        // top: 53%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        .swiper-container {
          width: 100%;
          height: 260px;
          .swiper-wrapper {
            .swiper-slide {
              img {
                width: 140px;
                object-fit: cover;
                box-shadow: 8px 8px 0px 0px rgba(219, 0, 0, 1);
              }
              /* Center slide text vertically */
              display: -webkit-box;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              -webkit-justify-content: center;
              justify-content: center;
              -webkit-box-align: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              align-items: center;
              transition: 300ms;
              transform: scale(1);
            }
            .swiper-slide-active,
            .swiper-slide-duplicate-active {
              transform: scale(1.2);
            }
          }
        }
      }
    }
    .part5 {
      margin: 50px 0;
      .title {
        width: 229.5px;
        height: 58.5px;
      }
      .titles {
        font-size: 12px;

        text-align: center;
        color: #333333;
        line-height: 20px;
        margin: 12px 0 20px 0;
      }
      .bottom-text {
        margin: 12px 0 0px;
        font-size: 9px;

        font-weight: 700;
        text-align: center;
        color: #333;
        span {
          color: #f00000;
        }
      }
      .swiperbox {
        width: 80%;
        height: 275px;
        .swiper-slide {
          height: 275px;
          position: relative;
          img{
            height: 275px;
          }
          .bottoms {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 46px;
            background: linear-gradient(
              0deg,
              #000000 0%,
              rgba(0, 0, 0, 0) 100%
            );
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;

            text-align: center;
            color: #fffefe;
            line-height: 15px;
          }
        }
      }
    }
    .part6 {
      padding: 50px 0 78px 0;
      background: url("/subject/searchReport/wap/bg6.png") no-repeat;
      background-size: 100% 100%;
      .title {
        width: 229.5px;
        height: 58.5px;
      }
      .titles {
        font-size: 12px;

        text-align: center;
        color: #fff;
        line-height: 20px;
        margin: 12px 0 20px 0;
      }
      .bottom-text {
        margin: 12px 0 0px;
        font-size: 9px;

        font-weight: 700;
        text-align: center;
        color: #fff;
        span {
          color: #f00000;
        }
      }
      .swiperbox {
        width: 80%;
      }
    }
    .part7 {
      .bottom-text {
        margin: 12px 0 0px;
        font-size: 9px;

        font-weight: 700;
        text-align: center;
        color: #333;
        span {
          color: #f00000;
        }
      }
      margin: 50px 0;

      .title {
        width: 229.5px;
        height: 58.5px;
      }
      .titles {
        font-size: 12px;

        text-align: center;
        color: #333333;
        line-height: 20px;
        margin: 12px 0 20px 0;
      }
    }
    .part8 {
      padding: 50px 0 78px 0;
      background: url("/subject/searchReport/wap/bg6.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
      .title {
        width: 109px;
        height: 58.5px;
      }
      .titles {
        font-size: 12px;

        text-align: center;
        color: #fff;
        line-height: 20px;
        margin: 12px 0 20px 0;
      }
      .app-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 2rem;
      }

      .appcode-title {
        font-size: 0.9rem;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        color: #ff3333;
        line-height: 28px;
        margin-bottom: 13px;
      }
      .codeBody {
        color: #fff;
        text-align: center;
        > div:first-child {
          font-weight: 700;
          margin-bottom: 20px;
          font-size: 14px;
        }
        .codeBox {
          display: flex;
          justify-content: center;
          margin-bottom: 15px;
          margin-right: 30px;
          .codeItem {
            &:first-child {
              margin-right: 15px;
            }
            .canvasCode {
              border-radius: 6px;
            }
            div {
              line-height: 32px;
              // line-height: 48px;
              // background: #e4760b;
              background: linear-gradient(150deg, #ff2c41 0%, #ff1232 100%),
                #ff3333;
              color: #fff;
              border-radius: 4px;
              margin-top: 6px;
              // font-size: 20px;
              font-size: 14px;
            }
          }
        }
      }
      .serveBox {
        font-size: 0.68rem;
        font-size: 12px;
        margin: 15px 0;
        padding: 0 30px;
        color: #fff;
        &-serve {
          color: red;
          border-bottom: 1px solid red;
        }
        &-item {
          display: flex;
        }
      }
    }
  }
}
@media screen and (min-width: 380px) {
  .box .content .part4 .swiperBox[data-v-140245c8] {
    width: 83%;
  }
  .box .content .part4 .download[data-v-140245c8] {
    top: 29%;
  }
}
/deep/ .videoPlayer-dimensions {
  width: 344px;
  height: 165px;
}
</style>